<template>
  <div>

    <a ref="attachmentA"></a>

    <v-skeleton-loader
      :loading="firstLoad"
      :transition="transition"
      :tile="true"
      type="table"
    >
      <v-data-table
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="totalEntities"
        :loading="loading"
        :search="search"
        item-key="id"
        dense
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.languageId`]="{ item }">
          <v-chip class="ma-2" label small>
            <country-flag
              :country="getFlagFromLangCode(item.languageId)"
              size="small"
            />
            <span class="ml-2">{{ item.languageId }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-simple-checkbox
            v-model="item.isActive"
            color="success"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{
              $vuetify.lang.t("$vuetify.websiteusers")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="getDataFromApiDebounced"
              @input="getDataFromApiDebounced"
            ></v-text-field>


            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>You can use % as a wildcard</span>
            </v-tooltip>
            <v-spacer></v-spacer>

            <!-- #region Filtro su ruolo-->
            <v-select
              v-if="user_roles_configuration.enabled_roles.length > 0"
              :items="user_roles_configuration.enabled_roles"
              v-model="searchRole"
              label="Role"
              :clearable="true"
              class="mt-5 mr-2"
              dense
              solo
              @change="getDataFromApiDebounced"
              @input="getDataFromApiDebounced"
            ></v-select>
            <!-- #endregion -->

            <!-- #region Buttons -->
            <v-btn color="secondary" @click="exportData" class="mr-2">
              <v-icon left>mdi-export</v-icon>
              {{ $vuetify.lang.t("$vuetify.export") }}
            </v-btn>

            <v-btn color="secondary" @click="newItem">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              {{ $vuetify.lang.t("$vuetify.addNew") }}
            </v-btn>
            <!-- #endregion -->

            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              <v-card flat>
                <v-toolbar flat dark color="primary">
                  <v-container fluid>
                    <v-row align="center" justify="space-between">
                      <v-col>
                        <v-toolbar-title>{{
                          isInsert
                            ? $vuetify.lang.t("$vuetify.newItem")
                            : $vuetify.lang.t("$vuetify.editItem")
                        }}</v-toolbar-title>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn icon dark @click="close">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-toolbar>

                <v-container>
                  <ValidationObserver
                    ref="observer"
                    v-slot="{ handleSubmit /*, invalid,errors*/ }"
                  >
                    <form
                      @submit.prevent="handleSubmit(save)"
                      v-if="form_loaded"
                      novalidate
                    >
                      <v-card-actions>
                        <v-btn text color="secondary" @click="close">
                          <v-icon left>mdi-backspace-outline</v-icon>
                          {{ $vuetify.lang.t("$vuetify.cancel") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="secondary" type="submit">
                          <v-icon left>mdi-content-save</v-icon>
                          {{ $vuetify.lang.t("$vuetify.save") }}
                        </v-btn>
                      </v-card-actions>

                      <v-card-text>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$vuetify.lang.t('$vuetify.username')"
                          rules="required|max:100"
                        >
                          <v-text-field
                            v-model="editedItem.username"
                            :label="$vuetify.lang.t('$vuetify.username')"
                            required="required"
                            :error-messages="errors"
                            counter="true"
                          ></v-text-field>
                        </ValidationProvider>

                        <ValidationObserver>
                          <v-alert
                            border="right"
                            colored-border
                            type="error"
                            elevation="2"
                            v-if="!isInsert"
                          >
                            Inserire la password solo se si intendete
                            modificarla
                          </v-alert>
                          <ValidationProvider
                            :rules="
                              isInsert
                                ? 'required|password:@confirm'
                                : 'password:@confirm'
                            "
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="editedItem.newPassword"
                              :label="$vuetify.lang.t('$vuetify.password')"
                              counter="12"
                              type="password"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>

                          <ValidationProvider
                            name="confirm"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="editedItem.confirmPassword"
                              :label="
                                $vuetify.lang.t('$vuetify.confirmPassword')
                              "
                              counter="true"
                              type="password"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </ValidationObserver>

                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$vuetify.lang.t('$vuetify.email')"
                          rules="max:255"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            :label="$vuetify.lang.t('$vuetify.email')"
                            :error-messages="errors"
                            counter="true"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$vuetify.lang.t('$vuetify.name')"
                          rules="max:255"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            :label="$vuetify.lang.t('$vuetify.name')"
                            :error-messages="errors"
                            counter="true"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$vuetify.lang.t('$vuetify.surname')"
                          rules="max:255"
                        >
                          <v-text-field
                            v-model="editedItem.surname"
                            :label="$vuetify.lang.t('$vuetify.surname')"
                            :error-messages="errors"
                            counter="true"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$vuetify.lang.t('$vuetify.VATCode')"
                          rules="max:255"
                        >
                          <v-text-field
                            v-model="editedItem.vATCode"
                            :label="$vuetify.lang.t('$vuetify.VATCode')"
                            :error-messages="errors"
                            counter="true"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          :name="$vuetify.lang.t('$vuetify.DNI')"
                          rules="max:255"
                        >
                          <v-text-field
                            v-model="editedItem.dNI"
                            :label="$vuetify.lang.t('$vuetify.DNI')"
                            :error-messages="errors"
                            counter="true"
                          ></v-text-field>
                        </ValidationProvider>

                        <!-- #region Data di nascita -->
                        <v-menu
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedDateFormattedMomentjs"
                              :label="$vuetify.lang.t('$vuetify.bitrh_date')"
                              prepend-icon="mdi-calendar-edit"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="editedItem.birthDate = null"
                            ></v-text-field>
                          </template>

                          <v-date-picker
                            v-model="editedItem.birthDate"
                            :name="$vuetify.lang.t('$vuetify.bitrh_date')"
                            locale="it"
                          ></v-date-picker>
                        </v-menu>
                        <!-- #endregion -->

                        <!-- #region Data di scadenza -->
                        <v-menu
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px">

                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedExpirationDateFormattedMomentjs"
                            :label="$vuetify.lang.t('$vuetify.expiration_date')"
                            prepend-icon="mdi-calendar-edit"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="editedItem.expirationDate = null"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="editedItem.expirationDate"
                          :name="$vuetify.lang.t('$vuetify.expiration_date')"
                          locale="it"
                        ></v-date-picker>
                        </v-menu>
                        <!-- #endregion -->

                        <v-select
                          v-model="editedItem.nationId"
                          :items="nations"
                          :label="$vuetify.lang.t('$vuetify.nation')"
                          required="false"
                          item-text="name"
                          item-value="nationId"
                          clearable
                          bottom
                          auto
                          @click:clear="clearNation()"
                        ></v-select>

                        <v-select
                          v-model="editedItem.regionId"
                          :items="filteredRegions"
                          :label="$vuetify.lang.t('$vuetify.region')"
                          required="false"
                          item-text="name"
                          item-value="regionId"
                          clearable
                          bottom
                          auto
                          @click:clear="clearRegion()"
                        ></v-select>

                        <v-select
                          v-model="editedItem.provinceId"
                          :items="filteredProvinces"
                          :label="$vuetify.lang.t('$vuetify.province')"
                          required="false"
                          item-text="name"
                          item-value="provinceId"
                          clearable
                          bottom
                          auto
                          @click:clear="clearProvince()"
                        ></v-select>

                        <!-- #region Ruolo utente (configurazioni sito) -->
                        <v-select
                            v-model="editedItem.role"
                            v-if="user_roles_configuration.enabled_roles.length > 0"
                            :items="user_roles_configuration.enabled_roles"
                            :label="$vuetify.lang.t('$vuetify.role')"
                            :required = (user_roles_configuration.required)
                            :multiple = (user_roles_configuration.allow_multiple)
                            item-text = "text"
                            item-value = "value"
                        ></v-select>
                        <!-- #endregion -->

                        <v-switch
                          v-model="editedItem.privacyAccepted"
                          inset
                          :label="$vuetify.lang.t('$vuetify.privacy')"
                          color="success"
                        ></v-switch>

                        <v-switch
                          v-model="editedItem.isActive"
                          inset
                          :label="$vuetify.lang.t('$vuetify.active')"
                          color="success"
                        ></v-switch>

                        <v-switch
                          v-model="editedItem.newsletterAccepted"
                          inset
                          :label="$vuetify.lang.t('$vuetify.newsletter')"
                          color="success"
                        ></v-switch>
                      </v-card-text>

                      <v-card-actions class="pt-8">
                        <v-btn text color="secondary" @click="close">
                          <v-icon left>mdi-backspace-outline</v-icon>
                          {{ $vuetify.lang.t("$vuetify.cancel") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="secondary" type="submit">
                          <v-icon left>mdi-content-save</v-icon>
                          {{ $vuetify.lang.t("$vuetify.save") }}
                        </v-btn>
                      </v-card-actions>
                    </form>
                  </ValidationObserver>
                </v-container>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon medium class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
          <v-icon medium class="mr-2" v-if="CheckUserEnableNotify(item)" @click="SendUserNotify(item.webSiteUserId)">mdi-mail</v-icon>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>


<script>
import moment from "moment";
import axios from "axios";
import { baseApiUrl, apiCall } from "../utils/api";
import { getFlagFromLangCode } from "../utils/flags";
import Swal from 'sweetalert2';

import _ from "lodash";
import QueryString from 'qs';

export default {
  name: "Resources",
  props: {
    schemaKey: {
      type: String,
      default: "",
    },
  },
  components: {
   
  },
  data() {
    return {
      nations: [],
      regions: [],
      provinces: [],
      date: new Date().toISOString().substr(0, 10),
      tab: null,
      selected_language: "it",
      blockModal: false,
      dialog: false,
      totalEntities: 0,
      schema: {},
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: false,
      transition: "scale-transition",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      editedItem: {},
      defaultItem: {
        webSiteId: "",
      },
      canReorder: false,
      editedIndex: -1,
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.username"),
          align: "start",
          sortable: true,
          value: "username",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.surname"),
          align: "start",
          sortable: true,
          value: "surname",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.email"),
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.active"),
          align: "start",
          sortable: true,
          value: "isActive",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.createdAt"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.updatedAt"),
          align: "start",
          sortable: true,
          value: "updatedAt",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actions"),
          value: "actions",
          sortable: false,
        },
      ],
      state: {},
      isInsert: false,
      search: "",
      searchRole: "",
      user_roles_configuration:{
        allow_multiple: false,
        required: false,
        enabled_roles: [],
        endpoint_notify_user: "",
        condition_notify_user: []
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApiDebounced();
      }
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.editedItem.birthDate ? moment(this.editedItem.birthDate).format("YYYY-MM-DD") : "";
    },
    computedExpirationDateFormattedMomentjs() {
      return this.editedItem.expirationDate ? moment(this.editedItem.expirationDate).format("YYYY-MM-DD") : "";
    },
    filteredRegions() {
      let me = this;
      return _.filter(this.regions, function (o) {
        return o.nationId == me.editedItem.nationId;
      });
    },
    filteredProvinces() {
      let me = this;
      return _.filter(this.provinces, function (o) {
        return o.regionId == me.editedItem.regionId;
      });
    },
    // a computed getter
    schemaConfiguration: function () {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function () {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    isUpdate: function () {
      return !this.isInsert; // this.editedIndex > -1;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    },
    currentWebSiteObj() {
      return this.$store.getters.getCurrentWebSiteObj;
    } 
  },
  mounted() {
    this.getDataFromApiDebounced();

    this.getNations().then((data) => {
      this.nations = data.nations;
    });
    this.getRegions().then((data) => {
      this.regions = data.regions;
    });
    this.getProvinces().then((data) => {
      this.provinces = data.provinces;
    });

    this.getConfigurationUserRole();
  },
  beforeUpdate() {},
  methods: {
    clearNation() {
      this.editedItem.nationId = null;
      this.editedItem.regionId = null;
      this.editedItem.provinceId = null;
    },
    clearRegion() {
      this.editedItem.regionId = null;
      this.editedItem.provinceId = null;
    },
    clearProvince() {
      this.editedItem.provinceId = null;
    },
    onChangeState(value) {
      this.state = value;
    },
    getFlagFromLangCode(langCode) {
      return getFlagFromLangCode(langCode);
    },
    getDataFromApi() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let filters = {
          WebSiteId: this.currentWebSite,
          Page: page,
          Limit: itemsPerPage,
          SortBy: Array.isArray(sortBy) ? sortBy : [...sortBy],
          SortDesc: Array.isArray(sortDesc) ? sortDesc : [...sortDesc],
          GenericSearch: "%" + this.search + "%",
          Role: this.searchRole,
        };
        apiCall({ url: "WebSiteUsers/paged", data: filters, method: "GET" })
          .then((resp) => {
            this.firstLoad = false;
            //console.log("Paged Results Response",resp);
            let items = resp.results;
            const total = resp.rowCount;

            //console.log("items", items);

            this.loading = false;
            resolve({
              items,
              total,
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getNations() {
      return new Promise((resolve, reject) => {
        apiCall({ url: "Nations", data: null, method: "GET" })
          .then((resp) => {
            //console.log("getNations",resp);
            let nations = resp;
            resolve({
              nations,
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getRegions() {
      return new Promise((resolve, reject) => {
        apiCall({ url: "Regions", data: null, method: "GET" })
          .then((resp) => {
            //console.log("getRegions",resp);
            let regions = resp;
            resolve({
              regions,
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getProvinces() {
      return new Promise((resolve, reject) => {
        apiCall({ url: "Provinces", data: null, method: "GET" })
          .then((resp) => {
            //console.log("getProvinces",resp);
            let provinces = resp;
            resolve({
              provinces,
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getConfigurationUserRole(){
      var roles = [];

      //Leggo le configurazioni del sito, se ho il nodo 'user_roles_configuration'
      if(this.currentWebSiteObj && this.currentWebSiteObj.Configurations && this.currentWebSiteObj.Configurations.htmlEditor && this.currentWebSiteObj.Configurations.htmlEditor.user_roles_configuration)
      {
          var user_config = this.currentWebSiteObj.Configurations.htmlEditor.user_roles_configuration;
          if(user_config){

            //console.log(JSON.stringify(user_config));
            this.user_roles_configuration.allow_multiple = (user_config.allow_multiple === 'true');
            this.user_roles_configuration.required = (user_config.required === 'true');
            
            for (let i = 0; i < user_config.enabled_roles.length; i++) 
            {
                var role = { text: user_config.enabled_roles[i].text, value: user_config.enabled_roles[i].value};

                this.user_roles_configuration.enabled_roles.push(role);
            }
            this.user_roles_configuration.endpoint_notify_user = user_config.endpoint_notify_user;
            this.user_roles_configuration.condition_notify_user = user_config.condition_notify_user;
          }
      }

      return roles;
    },
    editItem(item) {

      //console.log(item.webSiteUserId);
      //console.log(this.currentWebSiteObj);
      //console.log("item",item,"this.editedIndex",this.editedIndex,"this.isUpdate",this.isUpdate);
      
      this.form_loaded = false;
      this.editedIndex = this.entities.indexOf(item);
      this.isInsert = false;
      this.tab = 0;

      apiCall({
        url: "WebSiteUsers/" + item.webSiteUserId,
        data: {
          WebSiteId: this.currentWebSite,
          Page: 0,
          Limit: 10,
        },
        method: "GET",
      })
      .then((resp) => {
        this.entities[this.editedIndex] = resp;
        //console.log(item);

        item.expirationDate = item.expirationDate ? moment(item.expirationDate).format("YYYY-MM-DD") : "";
        item.birthDate = item.birthDate ? moment(item.birthDate).format("YYYY-MM-DD") : "";

        this.editedItem = item;
        this.form_loaded = true;
        this.dialog = true;
      })
      .catch((err) => {});
    },
    newItem() {
      this.form_loaded = false;
      this.editedIndex = -1;
      this.isInsert = true;
      this.tab = 0;

      let webSiteId = this.$store.getters.getCurrentWebSite;

      var item = Object.assign({}, this.defaultItem);

      item = Object.assign({}, item);

      this.editedItem = item;
      this.form_loaded = true;
      this.dialog = true;
    },
    deleteItem(item) {
      this.$confirm("Cancellare l'elemento corrente?").then((res) => {
        //console.log(res);
        if (res) {
          this.blockModal = true;
          apiCall({
            url: "WebSiteUsers/" + item.webSiteUserId,
            data: {},
            method: "DELETE",
          })
            .then((resp) => {
              this.editedItem = -1;

              this.getDataFromApi().then((data) => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch((err) => {
              this.blockModal = false;
            });
        }
      });
    },
    onDialogClosed() {
      this.dialog = false;
      this.blockModal = false;
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    onSaved() {
      //console.log(this.editedItem);

      this.getDataFromApi().then((data) => {
        this.entities = data.items;
        this.totalEntities = data.total;
        this.onDialogClosed();
        this.blockModal = false;
      });
    },
    getDataFromApiDebounced: _.debounce(function () {
      this.getDataFromApi()
        .then((data) => {
          this.entities = data.items;
          this.totalEntities = data.total;
        })
        .catch((e) => {
          console.error(e);
        });
    }, 1000),
    close() {
      this.dialog = false;
    },
    save() {
      //console.log("editedItem", this.editedItem, "IsInsert", this.isInsert);
      this.blockModal = true;

      this.defaultItem.webSiteId = this.currentWebSite;
      this.editedItem.webSiteId = this.currentWebSite;
      let savePromises = [];
      if (this.isInsert) delete this.editedItem.webSiteId;
      savePromises.push(
        apiCall({
          url:
            "WebSiteUsers" +
            (this.isInsert ? "" : "/" + this.editedItem.webSiteUserId),
          data: Object.assign({}, this.defaultItem, this.editedItem),
          method: this.isInsert ? "POST" : "PUT",
        })
      );

      Promise.all(savePromises)
        .then((resp) => {
          this.getDataFromApi().then((data) => {
            this.entities = data.items;
            this.totalEntities = data.total;
            this.onDialogClosed();
          });
        })
        .catch((err) => {
          this.blockModal = false;
        });
    },
    exportData() {
      var me = this;
      var url = "";
      var o = "esportazione_utenti.xlsx";
        
      let filters = {
        WebSiteId: this.currentWebSite,
        GenericSearch: `%${this.search}%`,
        Role: this.searchRole,
      };

      axios({
        method: "GET",
        url: `${baseApiUrl}WebSiteUsers/UserExport?` + QueryString.stringify(filters),
        responseType: "blob"
      })
      .then(function (result) {
        var blob = new Blob([result.data], {
          type: result.headers["content-type"],
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          window.navigator.msSaveOrOpenBlob(blob, o);
        } else {
          me.$refs.attachmentA.href = URL.createObjectURL(blob);
          me.$refs.attachmentA.download = o;
          me.$refs.attachmentA.click();
        }
      });
    },
    SendUserNotify(WebSiteUserId){
      
      this.$confirm("Inviare notifica al cliente?").then((res) => 
      {
        if (res) 
        {
          axios({
                  method: "POST",
                  url: `${this.user_roles_configuration.endpoint_notify_user}/${WebSiteUserId}`
                })
                .then(function (response) {
                  console.log('success' + JSON.stringify(response));
                  if(response.status == 200){
                    Swal.fire({icon: 'success', title: 'OK', text: "Notifica inviata"})
                  }
                })
                .catch(function (response) {
                  console.log('error' + JSON.stringify(response));
                  Swal.fire({icon: 'error', title: 'Attention', text: "Errore durante invio della notifica"})
                });
        }
      }); 
    },
    CheckUserEnableNotify(item){
      var retval = false;

      //Controllo se nelle configurazioni del sito ho specificato una condizione per cui solo determinati utenti possono ricevere notifiche
      //Es. Ruolo o flag privacy
      if(this.user_roles_configuration.endpoint_notify_user && item.webSiteUserId)
      {
        if(this.user_roles_configuration.condition_notify_user)
        {
            //ciclo le varie regole
            for (let i = 0; i < this.user_roles_configuration.condition_notify_user.length; i++) 
            {
                const element = this.user_roles_configuration.condition_notify_user[i];

                //Controllo se ho impostato una regola su ruolo utente
                if(Object.keys(element)[0] == 'role')
                {
                  if(Object.values(element)[0])
                  {
                      retval = item.role == Object.values(element)[0];
                  }
                }
            }
        } 
        else
        {
            //Nessuna condizione, tutti gli utenti sono abilitati per le notifiche
            retval = true;
        } 
      }  
      return retval;
    }
  },
};
</script>